import classNames from 'classnames'

interface FloatingLabelTextAreasProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string
  id?: string
  // eslint-disable-next-line no-restricted-globals
  name?: string
  className?: string
  useHtmlValidation?: boolean
}

const FloatingLabelTextAreas = ({
  label,
  id,
  name = id,
  className,
  required,
  useHtmlValidation = false,
  disabled,
  ...rest
}: FloatingLabelTextAreasProps) => (
  <div
    className={classNames(
      'relative overflow-hidden rounded-md border border-neutral-400 shadow-sm focus-within:border-neutral-800 focus-within:ring-1 focus-within:ring-neutral-800',
      className,
    )}
  >
    <textarea
      id={id}
      name={name}
      className={classNames(
        'peer block w-full border-0 bg-gray-50 p-0 px-2.5 pb-1.5 pt-5 text-sm text-gray-900 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm',
        { 'bg-disabled-color': disabled },
      )}
      disabled={disabled}
      placeholder=" "
      required={useHtmlValidation && required}
      {...rest}
    />
    <label
      htmlFor={id}
      className="absolute top-1.5 left-2.5 z-10 origin-[0] translate-y-0 transform !text-xs text-neutral-500 duration-300 peer-placeholder-shown:top-6 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:!text-sm peer-focus:top-1.5 peer-focus:translate-y-0 peer-focus:!text-xs peer-focus:text-title-color"
    >
      {label}
      {required && (
        <span
          className="ml-1 text-red-400"
          aria-hidden="true"
        >
          *
        </span>
      )}
    </label>
  </div>
)

export default FloatingLabelTextAreas
