import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import ConfirmModal from '@@/ConfirmModal'
import Image from 'next/image'

const confirmMessage =
  'Are you sure you to leave this page ? you risk losing the information entered'
const useConfirmLeavePage = (isUnsavedChanges: boolean) => {
  const router = useRouter()
  const [confirm, setConfirm] = useState(false)
  const [visible, setVisible] = useState(false)
  const [route, setRoute] = useState<null | string>(null)
  useEffect(() => {
    if (!isUnsavedChanges) {
      return
    }
    const handleRouterChange = (url: string) => {
      if (!confirm && url !== router.asPath) {
        setRoute(url)
        setVisible(true)
        // eslint-disable-next-line no-throw-literal
        throw 'routeChange aborted.'
      }
    }
    const onCloseOrRefreshWindow = (e: BeforeUnloadEvent) => {
      e.returnValue = confirmMessage
    }

    window.addEventListener('beforeunload', onCloseOrRefreshWindow)
    router.events.on('routeChangeStart', handleRouterChange)

    // eslint-disable-next-line consistent-return
    return () => {
      router.events.off('routeChangeStart', handleRouterChange)
      window.removeEventListener('beforeunload', onCloseOrRefreshWindow)
    }
  }, [router, confirm, isUnsavedChanges])

  useEffect(() => {
    if (confirm && route) {
      router.push(route)
    }
  }, [confirm, route, router])

  const ConfirmLeavePage = () => (
    <ConfirmModal
      okText="Leave the space"
      cancelText="Stay here"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => setConfirm(true)}
    >
      <div className="w-[600px]">
        <div className="flex flex-col items-center">
          <Image
            src="/illustrations/confirm-leave-page.svg"
            width={291}
            height={161}
            alt="confirm leave page"
          />
          <div>
            <p className="text-base font-normal text-neutral-800 mt-4">
              {confirmMessage.split('?')[0]}?<br />
              {confirmMessage.split('?')[1]}
            </p>
          </div>
        </div>
      </div>
    </ConfirmModal>
  )
  return {
    ConfirmLeavePage,
  }
}

export default useConfirmLeavePage
