import classNames from 'classnames'

interface CentredProps {
  children: React.ReactNode
  className?: string
}

const Centered = ({ children, className }: CentredProps) => (
  <div className={classNames('w-full flex justify-center', className)}>
    {children}
  </div>
)

export default Centered
