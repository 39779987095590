import Button from '@@/Button'

interface ConfirmModalProps {
  okText?: String
  onOk?: () => void
  cancelText?: String
  onCancel?: () => void
  visible?: boolean
  children: React.ReactNode
}
const ConfirmModal = ({
  okText = 'Ok',
  onOk,
  cancelText = 'Cancel',
  onCancel,
  visible,
  children,
}: ConfirmModalProps) =>
  visible ? (
    <div className="fixed z-40 inset-0  bg-black/[0.5] flex items-center justify-center">
      <div className=" w-full max-w-[500px] relative p-4  h-full md:h-auto">
        <div className="relative border border-red py-4 bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            onClick={onCancel}
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 pt-12 text-center">
            <div className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {children}
            </div>
            <Button
              data-testid="modal-ok"
              onClick={onOk}
              type="button"
              buttonType="outlined"
              className="mr-3"
            >
              {okText}
            </Button>
            <Button
              data-testid="modal-cancel"
              onClick={onCancel}
              type="button"
              buttonType="primary"
            >
              {cancelText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : null

export default ConfirmModal
