import React, { Fragment, useEffect, useMemo } from 'react'
import { Transition } from '@headlessui/react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'

interface NotificationProps {
  title?: string
  description?: string
  visible: boolean
  setVisible: any
  type: 'success' | 'error' | 'warning'
  time?: number // in seconds
}

const Notification = ({
  title,
  description,
  visible,
  setVisible,
  type,
  time,
}: NotificationProps) => {
  const Icon = useMemo(() => {
    if (type === 'success') {
      // eslint-disable-next-line func-names, react/display-name
      return function () {
        return (
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        )
      }
    }
    if (type === 'warning') {
      // eslint-disable-next-line func-names, react/display-name
      return function () {
        return (
          <ExclamationTriangleIcon
            className="h-6 w-6 text-yellow-400"
            aria-hidden="true"
          />
        )
      }
    }
    if (type === 'error') {
      // eslint-disable-next-line func-names, react/display-name
      return function () {
        return (
          <ExclamationCircleIcon
            className="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
        )
      }
    }
    return null
  }, [type])

  useEffect(() => {
    if (!visible) {
      return
    }
    setTimeout(
      () => {
        setVisible(false)
      },
      time ? time * 1000 : 5000,
    )
  }, [setVisible, time, visible])

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={visible}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            data-testid="notification"
            className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {/* @ts-ignore */}
                  <Icon />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{title}</p>
                  <p className="mt-1 text-sm text-gray-500">{description}</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    type="button"
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setVisible(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default Notification
