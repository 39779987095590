import { useState, useCallback } from 'react'
import isMatch from 'lodash.ismatch'

interface useCanUpdateReturn {
  disabled: boolean
  handleValuesChanges: (values: any) => void
  setDisabled: (value: boolean) => void
}

const useCanUpdate = (initialData: any, form: any): useCanUpdateReturn => {
  const [disabled, setDisabled] = useState(true)

  const handleValuesChanges = useCallback(
    (values) => {
      if (
        !isMatch(initialData, {
          ...form.getFieldsValue(),
          ...values,
        })
      ) {
        return setDisabled(false)
      }
      return setDisabled(true)
    },
    [initialData, setDisabled, form],
  )
  return {
    disabled,
    handleValuesChanges,
    setDisabled,
  }
}

export default useCanUpdate
